import React, { useState, useEffect } from 'react';
import { auth, fireStore } from '../../firebase';

function Data(props) {

    const [identities, setIdentities] = useState()

    useEffect(() => {
        let preArr = []
        let getData = async () => {await fireStore.collection("identities").get().then((data) => {
            data.docs.map((datum) => {
                var obj = datum.data()
                obj.StringDate = new Date(datum.data().entered).toDateString();
                console.log(obj);
                preArr.push(obj);
            });
        })
        setIdentities(preArr);
    }
    getData();
    }, [])

    let logout = () => {
        console.log('logged out...')
        auth.signOut();
    }

    return (
        <div className="data" style={{ marginTop: '3em' }}>
        <p onClick={() => {logout()}} >logout</p>
            {identities ? <div style={{ margin: '5em' }}>
                {console.table(identities)}
                {identities.map((data, i) => {
                    return <div key={i}><b>{data.business}</b><br />{data.firstName} {data.lastName}: {data.position}<br />{data.email}<hr /></div>
                })}
            </div> : 'loading'}
        </div>
    );
}


export default Data; 