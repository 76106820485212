import React, { useState } from 'react';
import { auth } from '../../firebase'
import { Layout, Icon, Drawer } from 'antd'

function Header(props) {

    const [visible, setVisible] = useState(false)

    const openMenu = () => {
        console.log('menu opened')
        setVisible(true)
      }
    
      const onClose = () => {
        setVisible(false)
      }

      const logout = () => {
        console.log('logged out...')
        auth.signOut();
        setVisible(false)
      }

      const { Header } = Layout;

    return (
        <div className="header">
            <Layout>
                <Header style={{ backgroundColor: '#DCDCDC', textAlign: 'center', paddingBottom: '1em', height: '140%' }} className="header">
                    {/* <span style={{float: 'left'}} onClick={openMenu}><Icon type="menu-unfold"/></span> */}
                    <span style={{fontSize: "3.2vw" }}><b>QR Code for <u>Your</u> Google Reviews</b></span>
                </Header>

            </Layout>

        </div>
    );
}

export default Header; 
 