import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

var config = {
    apiKey: "AIzaSyDiEOJxRkVx7w5ElIAgdahie9hhYjIlYzA",
    authDomain: "lessons-ace65.firebaseapp.com",
    databaseURL: "https://lessons-ace65.firebaseio.com",
    projectId: "lessons-ace65",
    storageBucket: "lessons-ace65.appspot.com",
    messagingSenderId: "118903995184",
    appId: "1:118903995184:web:fd97a1d91020f298f826f5"
}

export const app = firebase.initializeApp(config);

export const auth = firebase.auth();
export const fireStore = firebase.firestore();
export const storage = firebase.storage();